<template>
    <div class="about lg:pt-0 pt-12">
        <h2 class="text-4xl font-bold mb-6 text-agra-green-dark">
          {{ heading }}
        </h2>
        <div class="bg-white relative">
            <span class="absolute w-full h-full -inset-1.5 bg-black bg-opacity-10 blur-md"></span>
            <div class="relative bg-white">
                <img src="/images/hero.jpg" alt="hero" class="w-full">
                <div class="p-4">
                    <h5 class="text-2xl font-medium mb-3 text-agra-green-dark">
                      {{ subheading }}
                    </h5>
                    <p class="mb-4 text-agra-green-dark">
                      {{
                        snippet
                      }}
                    </p>
                    <a href="/about" class="inline-block underline">Read on</a>
                    <div class="mt-4 text-right">
                        <a href="/about" class="inline-block"><i class="fas fa-arrow-right text-agra-green"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "About",
  data() {
    return {
      heading: '',
      subheading: '',
      snippet: ''
    }
  },
  watch: {
    'staticContent': {
      deep: true,
      handler: function () {
        this.setFooter();
      }
    }
  },
  computed: {
    ...mapState([
      'staticContent'
    ])
  },
  methods: {
    setFooter() {
     this.heading = this.staticContent['Homepage About section heading'];
     this.subheading = this.staticContent['Homepage About subheading'];
     this.snippet = this.staticContent['Homepage About snippet'];
    }
  }
}
</script>
