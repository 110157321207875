<template>
  <div class="relative">
    <div class="absolute inset-0 w-full h-full bg-triangles-pattern-white opacity-50 -z-10"></div>
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import firebase from "../firebase"
import store from '@/store';

import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'

export default {
  store,
  components: {
    Footer,
    Header,
  },
  data() {
    return {
      user: null
    }
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.user = user
      }
    })
  },
  mounted() {
    this.fetchStaticContent();
  },
  methods: {
    ...mapActions([
        'fetchStaticContent'
    ]),
    signout() {
      firebase.auth().signOut()
      this.$router.push({
        name: "Login"
      })
      this.user = null
    }
  }
}
</script>
