import { createStore } from "vuex"
import QuestionDataService from "../services/QuestionDataService"
import ReportDataService from "../services/ReportDataService"
import StaticContentService from "../services/StaticContentService";

const store = createStore({
    state: {
        store: null,
        faqs: [],
        reports: [],
        appReady: false,
        activeFaq: null
    },
    mutations: {
        setFaqs(state, faqs) {
            state.faqs = faqs;
        },
        setActiveFaq(state, activeFaq) {
            state.activeFaq = activeFaq;
        },
        setStaticContent(state, content) {
            state.staticContent = content;
        },
        setReports(state, reports) {
            state.reports = reports;
        },
        setAppReady(state, appReady) {
            state.appReady = appReady;
        },
    },
    getters: {
        homePageFaqs: state => {
            return state.faqs.filter(faq => faq.onHomepage)
                .sort((a, b) => (a.onHomepageOrder > b.onHomepageOrder) ? 1 : -1)
                .splice(0,3);
        },
        homePageBottomFaqs: state => {
            return state.faqs/*.filter(faq => faq.home)*/.splice(0,3);
        },
        featureReport: state => {
            return state.reports.filter(report => report.onHomepage)
                .sort((a, b) => (a.onHomepageOrder > b.onHomepageOrder) ? 1 : -1)[0];
        },
        homePageReports: state => {
            return state.reports.filter(report => report.onHomepage)
                .sort((a, b) => (a.onHomepageOrder > b.onHomepageOrder) ? 1 : -1)
                .slice(1);
        },
    },
    actions: {
        async fetchStaticContent({commit}) {
            StaticContentService.get().onSnapshot(function (staticContents)
            {
                let data = null;
                staticContents.forEach((content) => {
                    data = content.data();
                });

                commit('setStaticContent', data);
            });
        },
        async fetchFaqs({commit}) {
            QuestionDataService.getAll().onSnapshot(function (items) {
                let _faqs = [];

                items.forEach((item) => {
                    let id = item.id;
                    let data = item.data();

                    _faqs.push({
                        id: parseInt(id),
                        question: data.question,
                        answer: data.answer,
                        categories: data.categories,
                        tags: data.tags,
                        onHomepage: data.on_homepage,
                        onHomepageOrder: data.on_homepage_order
                    });
                });

                commit('setFaqs', _faqs);
            });
        },
        async fetchReports({commit}) {
            ReportDataService.getAll().onSnapshot(function (items) {
                let _reports = [];

                items.forEach((item) => {
                    let id = item.id;
                    let data = item.data();

                    if (data.published) {
                        _reports.push({
                            id: id,
                            embed: data.embed,
                            previewDescription: data.preview_description,
                            explanation: data.explanation,
                            image: data.image,
                            sources: data.sources,
                            title: data.title_short,
                            titleLong: data.title_long,
                            country: data.country,
                            onHomepage: data.on_homepage,
                            onHomepageOrder: data.on_homepage_order,
                            state: data.state,
                            city: data.city,
                            datasets: data.datasets,
                            tags: data.tags,
                            pdf: data.pdf,
                            url: data.chart_url
                        })
                    }
                });

                commit('setReports', _reports);
            })
        },
    }
});

export default store
