<template>
  <footer class="bg-triangles-pattern-green text-white px-4">
    <div class="container pt-12 pb-6">
      <a href="/" class="inline-block mb-5">
        <img src="/images/logo-white.png"
             alt="AGRA"
             width="94"
        >
      </a>
      <div class="max-w-sm">
        <p>
          {{ footerDescription }}
        </p>
      </div>
      <p class="sm:hidden block">Powered by
        <a href="https://takwimu.africa">
          <img src="/images/logo-takwimu-white.png" alt="Takwimu" class="inline-block">
        </a>
      </p>
    </div>
    <div class="border-t border-agra-green-light border-opacity-30 py-6">
      <div class="container">
        <div class="flex flex-wrap justify-between items-center">
          <ul class="flex space-x-8">
            <li v-for="item in navigation.main"
                :key="item.name"
            >
              <router-link :to="item.href"
                 class="underline text-xs"
              >
                {{ item.name }}
              </router-link>
            </li>
          </ul>
          <p class="hidden text-sm sm:block">
            Powered by
            <a href="https://takwimu.africa" class="ml-2">
              <img src="/images/logo-takwimu-white.png" alt="Takwimu" class="inline-block">
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Footer",
  data() {
    return {
      navigation: {
        main: [
          { name: 'Privacy', href: '/privacy' },
          { name: 'Terms and conditions', href: '/terms' },
          { name: 'Creative commons license', href: '/license' }
        ],
      },
      footerDescription: ''
    }
  },
  watch: {
    'staticContent': {
      deep: true,
      handler: function () {
        this.setFooter();
      }
    }
  },
  computed: {
    ...mapState([
      'staticContent'
    ])
  },
  methods: {
    setFooter() {
      this.navigation.main[0].name = this.staticContent['Footer link1'];
      this.navigation.main[1].name = this.staticContent['Footer link2'];
      this.navigation.main[2].name = this.staticContent['Footer link3'];
      this.footerDescription = this.staticContent['Footer Description'];
    }
  }
}
</script>
