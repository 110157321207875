import firebase from "../../firebase"

const db = firebase.firestore().collection("/reports")

export default {
    getAll() {
        return db;
    },
    oneReport(id) {
        return db.doc(id);
    }
}