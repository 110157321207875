import {createRouter, createWebHistory} from "vue-router";
import firebase from "../../firebase";
import Home from "../views/Home/index";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/about",
        name: "About",
        component: () =>
            import("../views/About.vue"),
    },
    {
        path: "/privacy",
        name: "Privacy",
        component: () =>
            import("../views/Privacy.vue"),
    },
    {
        path: "/terms",
        name: "Terms",
        component: () =>
            import("../views/Terms.vue"),
    },
    {
        path: "/license",
        name: "License",
        component: () =>
            import("../views/License.vue"),
    },
    {
        path: "/knowledgebase",
        name: "Knowledgebase",
        component: () =>
            import("../views/Knowledgebase.vue")
    },
    {
        path: "/login",
        name: "Login",
        component: () =>
            import("../views/Login.vue"),
        meta: {
            guest: true
        }
    },
    {
        path: "/data-explorer",
        name: "Data Explorer",
        component: () =>
            import("../views/DataExplorer.vue")
    },
    {
        path: "/data-explorer/:id",
        name: "Report",
        component: () =>
            import("../views/Report.vue")
    },
    {
        path: "/profile",
        name: "Profile",
        component: () =>
            import("../views/Profile.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: "Not found",
        component: () =>
            import("../views/PageNotFound.vue")
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                next()
            } else {
                next({
                    path: "/login"
                })
            }
        })
    } else if (to.matched.some(record => record.meta.guest)) {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                next({
                    path: "/profile"
                })
            } else {
                next()
            }
        })
    } else {
        next()
    }
});

router.afterEach((to) => {
    if (to.meta.title) {
        document.title = `${to.meta.title} | ${process.env.VUE_APP_TITLE}`;
    }
});

export default router;
