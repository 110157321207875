<template>
    <div class="h" v-if="faqs.length">
        <h2 @click="test" class="text-4xl font-bold mb-6 text-agra-green-dark">
            From the knowledge base
        </h2>
        <div class="grid gap-10">
            <FAQMicro v-for="faq in faqs"
                 :key="faq.id"
                 :faq="faq"
            >
            </FAQMicro>
        </div>
        <a href="#" class="bg-agra-green py-4 px-6 inline-block sm:hidden mt-8 text-white">
            The full knowledge<i class="fas fa-arrow-right text-sm ml-1"></i>
        </a>
    </div>
</template>

<script>
import FAQMicro from '../../shared/FAQMicro';

export default {
    props: ['faqs'],
    components: {
        FAQMicro
    }
}
</script>

