<template>
  <section class="px-4 py-20">
    <div class="container">
      <h2 class="font-bold text-agra-green-dark font-montserrat mb-14 lg:mb-20 text-2xl md:text-4xl max-w-[800px] w-full">
        {{ contentHeading }}
      </h2>
      <a href="#"
         class="bg-agra-green py-4 px-6 sm:hidden inline-block mb-8 text-white">
        Data Explorer <i class="fas fa-arrow-right text-sm ml-1"></i>
      </a>
      <Report v-if="featureReport" size="large" :report="featureReport"></Report>
      <div v-if="homePageReports.length" class="grid sm:grid-cols-2 lg:grid-cols-3 mt-12 gap-6">
        <Report v-for="report in homePageReports" :key="report.id" :report="report"></Report>
      </div>
      <div class="mt-8">
        <router-link to="/data-explorer"
                     class="py-4 px-5 inline-block rounded bg-agra-green-light text-white"
        >
          Data Explorer <i class="fas fa-arrow-right text-sm ml-1"></i>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
  import Report from '../../shared/Report';
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default {
    data() {
      return {
        unsubscribe: null,
        contentHeading: ''
      }
    },
    watch: {
      'staticContent': {
        deep: true,
        handler: function () {
          this.setContentHeading();
        }
      }
    },
    computed: {
      ...mapGetters([
          'featureReport',
          'homePageReports'
      ]),
      ...mapState([
        'staticContent'
      ])
    },
    components: {
      Report
    },
    methods: {
      ...mapActions(['fetchReports']),
      setContentHeading() {
      this.contentHeading = this.staticContent['Content Main Heading'];
      }
    },
    mounted() {
      this.fetchReports();
    }
  }
</script>

