import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/app-check"
import "firebase/compat/auth"

let config = {
    apiKey: "AIzaSyB8Xd6gwNejh1PiKetfIrXn5QENQ6arGw4",
    authDomain: "agradata.firebaseapp.com",
    projectId: "agradata",
    storageBucket: "agradata.appspot.com",
    messagingSenderId: "459422556044",
    appId: "1:459422556044:web:2050449c23b2360fac40a9",
    measurementId: "G-PLPPC8BT4X"
}

firebase.initializeApp(config)

const appCheck = firebase.appCheck();
appCheck.activate(
    '6LczCesgAAAAAFYE1ATFDGHPX36UUFrw4rB9vE93',
    true
)

export default firebase