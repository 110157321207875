<template>
    <div v-if="size === 'large'" class="relative">
        <div class="absolute w-full h-full -inset-1.5 bg-black bg-opacity-10 blur-md"></div>
        <div class="grid lg:grid-cols-2 xl:grid-cols-3 relative">
            <div v-html="report.embed" class="w-full xl:col-span-2 h-full iframe-wrapper"></div>
            <div class="p-5 relative bg-white flex flex-col">
                <h4 class="font-bold text-agra-green-dark font-montserrat mb-6 text-2xl md:text-3xl">
                    {{ report.titleLong }}
                </h4>
                <p class="text-xs text-inclusionGray">{{ report.country}}{{report.state || report.city ? ', ': ' '}}{{ report.state ? report.state : report.city }}</p>
                <p class="text-sm text-agra-green-dark my-6 max-h-40 overflow-auto" v-html="report.explanation"></p>
                <div class="flex flex-wrap my-4">

                <router-link v-for="tag in report.tags"
                            :to="{ name: 'Data Explorer', query: {tag: tag}}"
                            :key="'rep-' + report.id + '-tag-' + tag"
                            class="bg-agra-green text-xs text-white px-4 py-1 rounded-full mr-3 mb-3 block">
                        {{ tag }}
                </router-link>
                </div>
                <div class="flex flex-wrap">
                    <a
                        v-for="(dataset, index) in report.datasets"
                        :key="'dataset-' + index"
                        :href="getDatasetLink(dataset)"
                        class="underline block text-inclusionGray text-xs mr-4"
                    >{{ dataset }}</a>
                </div>
                <ul v-if="actions" class="flex space-x-5 flex-wrap items-center mt-auto">
                    <li>
                        <a @click="showEmbed = true" href="#" class="open-modal flex items-center border border-agra-green-dark text-agra-green-dark px-3 py-2 rounded text-xs">
                            <span>Embed</span> <i class="fas fa-code ml-2"></i>
                        </a>
                    </li>
                    <li>
                        <a @click="showShare = true" href="#" class="flex items-center border border-agra-green-dark text-agra-green-dark px-3 py-2 rounded text-xs">
                            <span>Share</span> <i class="fas fa-arrow-right transform -rotate-45 ml-2"></i>
                        </a>
                    </li>
                    <li>
                        <a :href="report.url" target="_blank" class="flex items-center border border-agra-green-dark text-agra-green-dark px-3 py-2 rounded text-xs">
                            <span>Download</span> <i class="fas fa-file ml-2"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div v-else class="relative block group">
        <span class="absolute w-full h-full -inset-1.5 bg-black bg-opacity-10 blur-md group-hover:-inset-2 group-hover:bg-opacity-20"></span>
        <div class="relative bg-white h-full flex flex-col">
            <a @click="emitReport" class="cursor-pointer" title="Click to load into explorer">
                <img v-if="report.image" :src="`${report.image}`" :alt="report.title" title="Click to load into explorer" class="w-full">
            </a>
            <div class="p-5 pb-2 flex-1 flex flex-col">
                <h4 class="text-2xl text-agra-green-dark font-medium mb-4">{{ report.title }}</h4>
                <p class="text-xs text-inclusionGray">
                    <router-link :to="{ name: 'Data Explorer', query: {region: report.country}}">{{ report.country }}</router-link>
                    {{report.state ? ', ': ' '}}{{ report.state}}
                </p>
                <p class="mb-8 mt-4 text-agra-green-dark" v-html="report.previewDescription"></p>
                <div class="flex flex-wrap">
                    <a
                        v-for="(dataset, index) in report.datasets"
                        :key="'dataset-' + index"
                        :href="getDatasetLink(dataset)"
                        class="underline block text-inclusionGray text-xs mr-4"
                    >{{ dataset }}</a>
                </div>
                <div class="group flex justify-between items-center mt-auto">
                    <div class="flex flex-wrap mt-4">
                      <router-link v-for="tag in report.tags"
                            :to="{ name: 'Data Explorer', query: {tag: tag} }"
                            :key="'rep-' + report.id + '-tag-' + tag"
                            class="bg-agra-green text-xs text-white px-4 py-1 rounded-full mr-3 mb-3 block">
                        {{ tag }}
                      </router-link>
                    </div>
                    <a @click="emitReport" class="cursor-pointer opacity-0 group-hover:opacity-100">
                        <i class="fas fa-arrow-right text-agra-green"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <ShareReport :show="showShare" @close="showShare = false"></ShareReport>
    <EmbedReport :show="showEmbed" @close="showEmbed = false" :report="report"></EmbedReport>
</template>

<script>
    import ShareReport from '../../modals/ShareReport.vue'
    import EmbedReport from '../../modals/EmbedReport.vue'

    export default {
        props: {
            size: {
                required: false,
                type: String
            },
            report: {
                required: true,
                type: Object
            },
            actions: {
                required: false,
            },
        },
        data() {
            return {
                showEmbed: false,
                showShare: false
            }
        },
        components: {
            ShareReport,
            EmbedReport
        },
        methods: {
            getDatasetLink(dataset) {
                let map = {
                    'EFInA Access to Finance surveys 2008 - 2020': 'https://efina.org.ng/',
                    'NIMC Monthly reports 2021': 'https://nimc.gov.ng/',
                    'World Bank 2021': 'https://www.povertyindex.org/'
                };

                if (Object.prototype.hasOwnProperty.call(map, dataset)) {
                    return map[dataset];
                }

                return '#';
            },
            emitReport() {
                this.$router.push({ name: 'Data Explorer', query: {report: this.report.id}});
                this.$parent.featuredReport = null;
                this.$parent.featuredReport = this.report;
                window.scrollTo(0, 0);
            }
        }
    }
</script>

<style>
    .iframe-wrapper > iframe {
        width: 100% !important;
        height: 613px;
    }

    html {
        scroll-behavior: smooth;
    }
</style>
