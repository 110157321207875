<template>
    <header :class="openMenu ? 'shadow' : ''"
            class="header shadow bg-white p-4 sm:py-5 fixed left-0 w-full top-0 z-50">
      <div class="container">
        <div class="flex justify-between items-center">
          <a href="/">
            <img src="/images/logo.png"
                 alt="AGRA Growing Africa's Agriculture"
            >
          </a>
          <button @click="toggleMenu"
                  class="menuBtn bg-agra-green text-white w-24 px-5 py-3 sm:hidden flex items-center font-light
                  rounded-none border-none focus:outline-none"
          >
            <span class="menu-text">
              {{ openMenu ? 'Close' : 'Menu'}}
            </span>
            <i :class="openMenu ? 'fa-times' : ''" class="fas fa-bars ml-1 bar"></i>
          </button>
          <nav class="sm:block hidden">
            <!-- Desktop Menu  -->
            <ul class="menu flex space-x-6">
              <li v-for="item in navigation.main"
                  :key="item.name"
              >
                <router-link :to="item.href" class="font-montserrat font-medium relative text-agra-green-dark group">
                  {{ item.name }}
                  <span class="block absolute left-0 -bottom-0.5 w-0 h-1 bg-agra-green-light group-hover:w-full
                   transition-all"></span>
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
    <!-- Mobile Menu -->
    <div class="sm:hidden flex flex-col justify-between bg-white z-40 p-4 fixed h-screen -left-full bottom-0 w-full
    top-0 transition-all mobile-menu">
      <ul class="flex flex-col pt-32">
        <li v-for="item in navigation.main"
            :key="item.name"
            class="mb-5 pb-5 border-b border-[#e5e5e5]"
        >
          <a :href="item.href" class="text-3xl font-bold">
            {{ item.name }}
          </a>
        </li>
      </ul>
      <div class="mt-3">
        <p class="text-inclusionLightBlack mb-20">
          An Africa Practice initiative <br>
          in association with Donec sed odio dui.
        </p>
        <p class="mb-12">
          Powered by
          <a href="https://takwimu.africa">
            <img src="/images/logo-takwimu.png" alt="Takwimu" class="inline-block">
          </a>
        </p>
        <ul class="flex space-x-2">
          <li><a href="#" class="text-inclusionLightBlack underline text-xs">Privacy</a></li>
          <li><a href="#" class="text-inclusionLightBlack underline text-xs">Terms and conditions</a></li>
          <li><a href="#" class="text-inclusionLightBlack underline text-xs">Creative commons license</a>
          </li>
        </ul>
      </div>
    </div>
</template>

<script>
const navigation = {
  main: [
    { name: 'About', href: '/about' },
    { name: 'Data Explorer', href: '/data-explorer' },
    { name: 'Methodology', href: '/knowledgebase' }
  ],
};

export default {
  name: "Footer",
  data() {
    return {
      openMenu: false
    }
  },
  setup() {
    return {
      navigation,
    }
  },
  methods: {
    toggleMenu() {
      this.openMenu = ! this.openMenu;

      const mobileMenu = document.querySelector(".mobile-menu");

      if (mobileMenu.classList.contains("left-0")) {
        mobileMenu.classList.remove("left-0");
        mobileMenu.classList.add("-left-full");
      } else if (mobileMenu.classList.contains("-left-full")) {
        mobileMenu.classList.add("left-0");
        mobileMenu.classList.remove("-left-full");
      }
    }
  }
}
</script>
