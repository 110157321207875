<template>
    <modal v-if="report" :open="show" :embed="report.embed" @close="closeModal">
        <div>
            <div class="mt-3 text-center sm:mt-5">
                <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
                    Embed code
                </DialogTitle>
                <div class="mt-2">
                    <p class="text-sm text-gray-500">
                        <textarea class="w-full block" rows="5" id="text" :value="report.embed"></textarea>
                    </p>
                </div>
            </div>
        </div>
        <div class="mt-5 sm:mt-6">
            <button type="button" @click="copyToClipboard" id="btn" class="bg-agra-green py-3 px-4 inline-block w-full text-white">
                Copy to Clipboard
            </button>
        </div>
    </modal>
</template>

<script>
    import Modal from '../components/Modal.vue'
    import { DialogTitle } from '@headlessui/vue'

    export default {
        props: ['show', 'report'],
        components: {
            Modal,
            DialogTitle
        },
        methods: {
            closeModal() {
                this.$emit('close', true);
            },
            copyToClipboard() {
                const copyText = document.querySelector('#text');
                const btn = document.querySelector('#btn');

                copyText.select();
                document.execCommand("copy");
                btn.innerHTML = "Copied!";
                setTimeout(() => {
                    btn.innerHTML = "Copy to Clipboard";
                }, 1000)
            },
        }
    }
</script>
