<template>
    <Hero :faqs="homePageFaqs" :content="staticContent"></Hero>
    <div>
        <DataExplorer></DataExplorer>
        <section class="px-4 mb-20">
            <div class="container">
                <div class="grid lg:grid-cols-2 gap-10 xl:gap-20 border-t-2 border-agra-green pt-20">
                    <Knowledgebase :faqs="homePageFaqs"></Knowledgebase>
                    <About></About>
                </div>
                <div class="mt-8">
                  <a href="/knowledgebase"
                     class="py-4 px-5 inline-block rounded bg-agra-green-light text-white"
                  >
                    The full knowledge <i class="fas fa-arrow-right text-sm ml-1"></i>
                  </a>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Knowledgebase from './partials/Knowledgebase';
    import DataExplorer from './partials/DataExplorer';
    import Hero from './partials/Hero';
    import About from './partials/About';

    import { mapActions, mapGetters, mapState } from 'vuex';

    export default {
        name: "Home",
        components: {
            Knowledgebase,
            DataExplorer,
            Hero,
            About,
        },
        computed: {
            ...mapGetters([
                'homePageFaqs',
                'homePageBottomFaqs'
            ]),
            ...mapState([
                'staticContent'
            ])
        },
        methods: {
            ...mapActions([
                'fetchFaqs'
            ]),
        },
        async created() {
            await this.fetchFaqs();
        }
    };
</script>
