<template>
    <div class="relative h-screen">
        <img src="/images/hero.jpg" alt="Hero" class="w-full h-full object-cover absolute inset-0">
        <div class="container relative h-full">
            <div v-show="staticContent"
                 class="absolute z-20 bottom-12 left-0 bg-triangles-pattern-green p-6 border-l-4 border-agra-orange text-white max-w-[90%] mx-auto right-0 sm:right-auto sm:max-w-4xl w-full"
            >
                <h1 class="font-semibold font-montserrat sm:mt-0 mt-3 mb-3 text-3xl lg:text-5xl">
                    {{ staticContent ? staticContent['Header splash heading'] : '' }}
                </h1>
                <p class="text-sm mb-6">
                    {{ currentSplash }}
                </p>
                <div class="flex space-x-3 sm:mb-6">
                    <button
                            @click="setCurrentSplash(1)"
                            class="h-1.5 bg-white outline-none focus:outline-none transition-all"
                            :class="currentIndex === 1 ? 'w-16' : 'w-9 bg-opacity-40'"
                    ></button>
                    <button
                            @click="setCurrentSplash(2)"
                            class="h-1.5 bg-white outline-none focus:outline-none transition-all"
                            :class="currentIndex === 2 ? 'w-16' : 'w-9 bg-opacity-40'"
                    ></button>
                    <button
                            @click="setCurrentSplash(3)"
                            class="h-1.5 bg-white outline-none focus:outline-none transition-all"
                            :class="currentIndex === 3 ? 'w-16' : 'w-9 bg-opacity-40'"
                    ></button>
                    <button
                            @click="setCurrentSplash(4)"
                            class="h-1.5 bg-white outline-none focus:outline-none transition-all"
                            :class="currentIndex === 4 ? 'w-16' : 'w-9 bg-opacity-40'"
                    ></button>
                </div>
                <div>
                    <a href="/knowledgebase?category=use"
                       class="py-4 px-5 inline-block rounded bg-agra-green-light text-white"
                    >
                        How to use this website <i class="fas fa-arrow-right text-sm ml-1"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

    export default {
        props: ['content'],
        data() {
           return {
               currentSplash: '',
               currentIndex: 1,
           }
        },
        watch: {
          'staticContent': {
            deep: true,
            handler: function () {
              this.setCurrentSplash(1);
            }
          }
        },
        computed: {
            ...mapState([
                'staticContent'
            ])
        },
        methods: {
            setCurrentSplash(index) {
                this.currentSplash = this.staticContent ? this.staticContent['Header splash tab' + index] : '';
                this.currentIndex = index;
            }
        }
    }
</script>
